import { AxiosRequestConfig } from "axios";

import { LoginResponse } from "common/interface/interface";
import {
  ILogin,
  IRegister,
  IUserInfo,
  IcompanyAdd,
  IupdateProfile
} from "common/interface/login";
import { getApiHost } from "common/utils";
import Api from "./index";

const userLogin = (params: ILogin): Promise<LoginResponse<IUserInfo>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: `${getApiHost()}/api/users/login`,
    data: params
  };
  return Api(config);
};
const getUserInfo = (token: string): Promise<LoginResponse<IUserInfo>> => {
  const config = {
    method: "get",
    url: `${getApiHost()}/api/users/info`,
    headers: {
      Authorization: token
    }
  };

  return Api(config);
};
const uploadFile = (
  token: string,
  file: FormData
): Promise<LoginResponse<{ url: string }>> => {
  const config = {
    method: "post",
    url: `${getApiHost()}/api/common/upload`,
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data"
    },
    data: file
  };
  return Api(config);
};
const updateProfile = (
  token: string,
  params: IupdateProfile
): Promise<LoginResponse<null>> => {
  const config = {
    method: "post",
    url: `${getApiHost()}/api/users/update`,
    headers: {
      Authorization: token
    },
    data: params
  };
  return Api(config);
};
const companyAdd = (
  token: string,
  params: IcompanyAdd
): Promise<LoginResponse<null>> => {
  const config = {
    method: "post",
    url: `${getApiHost()}/api/company/add`,
    headers: {
      Authorization: token
    },
    data: params
  };
  return Api(config);
};

const userRegister = (params: IRegister): Promise<LoginResponse<IUserInfo>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: `${getApiHost()}/api/users/register`,
    data: params
  };
  return Api(config);
};
export { userLogin, getUserInfo, uploadFile, updateProfile, companyAdd, userRegister };
