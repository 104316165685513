import { useEffect, useRef, useState } from "react";
import { parseCookies } from "nookies";
import { getApiHost } from "@/common/utils";
import { uploadAvatarApi } from "@/common/request/member";

export default function useModal() {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const onModalShow = () => {
    setModalVisible(true);
  };
  const onModalCancel = () => {
    setModalVisible(false);
  };
  return {
    modalVisible,
    onModalShow,
    onModalCancel
  };
}

export function useFetchAiAvatar() {
  const abortControllerRef: any = useRef();
  const upload = async (params: any) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const { token } = parseCookies();
    return await fetch(`${getApiHost()}/api/tutoring/createHeadImage`, {
      method: "POST",
      body: JSON.stringify(params),
      signal: abortController.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(async res => {
        const _data = await res.json();
        const url = _data?.data?.image_urls?.[0];
        const response = await fetch(url);
        if (response?.ok) {
          const blob = await response.blob();
          const file = new File([blob], "avatar.png", {
            type: blob.type,
            lastModified: Date.now()
          });
          const formData = new FormData();
          formData.append("file", file);
          return uploadAvatarApi(formData).then(res => res.data);
        }
        return null;
      })
      .catch(err => {
        console.log(err);
      });
  };
  return {
    upload,
    abortController: abortControllerRef?.current
  };
}

export const useCountdown = (initialSeconds: number) => {
  const [timeLeft, setTimeLeft] = useState(initialSeconds);
  const [isRunning, setIsRunning] = useState(false);
  const timeId = useRef<any>();

  const start = () => {
    if (!isRunning) {
      setIsRunning(true);
      timeId.current = setInterval(() => {
        setTimeLeft(pre => pre - 1);
      }, 1000);
    }
  };

  const reset = () => {
    if (timeId?.current) {
      clearInterval(timeId.current);
    }
    setIsRunning(false);
    setTimeLeft(initialSeconds);
  };

  useEffect(() => {
    if (timeId?.current) {
      clearInterval(timeId?.current);
    }
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      reset();
    }
  }, [timeLeft, reset]);

  return { timeLeft, isRunning, start, reset };
};
