import React, { useEffect, useState } from "react";
import { GetServerSideProps } from "next";
import { App, Button, Checkbox, Form, Input, message, Tabs } from "antd";
import { setCookie } from "nookies";
import { useLocalStorageState, useCookieState, useMount, useRequest } from "ahooks";
import { MD5 } from "crypto-js";
import { useRouter } from "next/router";
import { R } from "common/request/index";
import { userLogin } from "common/request/login";
import { ILoginForm, IUserInfo, ICompany } from "common/interface/login";
import { LoginResponse } from "common/interface/interface";
import { menuData } from "@/components/menu/const";
import { getApiHost } from "@/common/utils";
import { reportGio } from "@/utils/gio";
import { useCountdown } from "../setting/useModal";
import Style from "./index.module.scss";

interface ILogin {
  data: ICompany;
}

const reg = /^1[3-9]\d{9}$/;

export default function Login(props: ILogin) {
  const { data: companyInfo } = props;
  const [form] = Form.useForm();
  const router = useRouter();
  const [loginRemember, setLoginRemember] = useCookieState("login_remember", {
    defaultValue: ""
  });

  const { runAsync, loading } = useRequest(userLogin, {
    manual: true
  });
  const [loginInfo, setLoginInfo] = useLocalStorageState<string>("loginInfo");
  const [showCoachTab, setShowCoachTab] = useLocalStorageState<string>("showCoachTab");
  const [isClient, setIsClient] = useState(false);
  const typeVal = Form.useWatch("type", form);
  const { timeLeft, isRunning, start, reset } = useCountdown(60);

  useEffect(() => {
    reportGio("page_login");
  }, []);

  const onFormFinish = (_: ILoginForm | null, isRemember?: boolean) => {
    const formRes = form.getFieldsValue();
    const formPassword = isRemember ? formRes.password : MD5(formRes.password).toString();

    const params = typeVal === "1" ? {
      account: formRes.account,
      password: formPassword
    } : {
      account: formRes.account,
      verify_code: formRes?.verify_code
    };

    runAsync(params).then(async (res: LoginResponse<IUserInfo>) => {
      reset();
      if (res.code === 0) {
        setLoginInfo(JSON.stringify(res.data ?? {}));
        message.success("登录成功");
        setCookie(null, "token", res.data.token, {
          maxAge: 86400 * 90,
          path: "/"
        });
        // 记住密码
        if (typeVal === "1" && formRes.remember) {
          setLoginRemember(() =>
            JSON.stringify({
              account: formRes.account,
              password: formPassword,
              remember: true
            })
          );
        }
        if (res.data?.role.id < 3 && !res.data?.company?.id) {
          console.log("没有企业信息");
          router.push("/create_company_space");
          return;
        }
        // if (!res.data.head_image) {
        //   router.push("/change_userinfo");
        // } else {
          let _menuList = menuData.filter(
            item =>
              !!item.filter?.({
                ...res?.data,
                enabled_course_types: res?.data?.settings?.company_functions?.scenes
              })
          );
          const hasTutoring = _menuList.some(item => item.key === "/coach");
          if (hasTutoring && res?.data?.is_trial_user) {
            router.push("/coach");
            return;
          }
          if (hasTutoring) {
            const tutorList = await R("course/tutoring_list", { pageSize: 1, client: "pc" });
            if (!tutorList?.data?.length && res?.data?.role?.id > 3) {
              setShowCoachTab(JSON.stringify({ show: false }));
              _menuList = _menuList.filter(item => item.key !== "/coach");
            } else {
              setShowCoachTab(JSON.stringify({ show: true }));
            }
          } else {
            setShowCoachTab(JSON.stringify({ show: false }));
          }
          router.push(_menuList?.[0]?.key || "/book");
        // }
      } else {
        message.error(res.message);
      }
    });
  };
  useMount(() => {
    if (loginRemember) {
      const loginData = JSON.parse(loginRemember as string);

      form.setFieldsValue({
        account: loginData.account,
        password: loginData.password,
        remember: true
      });
      onFormFinish(null, true);
    }
  });

  const getVerifyCode = async () => {
    const mobile_phone = form.getFieldValue("account");
    if (!reg.test(mobile_phone)) {
      message.error("请输入有效的手机号码");
      return;
    }
    const params = {
      mobile_phone,
      action: "login"
    };
    try {
      const res: any = await R("users/get_verify_code", params);
      if (res?.code === 0) {
        start();
      } else {
        message.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return <></>;
  }
  return (
    <div className={Style.login}>
      <div className={Style.left}>
        <div className={Style.title}>
          <img src={companyInfo?.logo || "/images/logo.svg"} alt="logo" />
          {companyInfo?.name || "Maner"}
        </div>
        <div className={Style.des}>
          <img src={companyInfo?.logo || "/images/logo.svg"} alt="logo" />
          {companyInfo?.name || "Maner"}
          智能培训平台结合最新的AI技术和自然语言处理能力，打造专业且灵活高效的行业知识学习平台，并通过权威测评帮助用户掌握自己的学习情况，获得个性化学习建议
        </div>
      </div>
      <div className={Style.right}>
        <div className={Style.formArea}>
          <div className={Style.formTitle}>登录</div>
          <Form
            layout="vertical"
            form={form}
            onFinish={(form: ILoginForm | null) => onFormFinish(form)}
            initialValues={{
              remember: true,
              type: "1"
            }}
          >
            <Form.Item
              name="type"
              style={{
                textAlign: "left"
              }}
            >
              <Tabs
                defaultActiveKey="1"
                items={[
                  { key: "1", label: "密码登录" },
                  { key: "2", label: "验证码登录" }
                ]}
              />
            </Form.Item>
            <Form.Item
              label="手机号"
              name="account"
              style={{
                textAlign: "left"
              }}
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input placeholder="请输入手机号" size="large" />
            </Form.Item>
            {typeVal === "1" ? (
              <Form.Item
                label="密码"
                style={{
                  textAlign: "left"
                }}
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password placeholder="请输入密码" size="large" />
              </Form.Item>
            ) : (
              <Form.Item
                label="验证码"
                style={{
                  textAlign: "left"
                }}
                name="verify_code"
                rules={[{ required: true, message: "请输入验证码" }]}
              >
                <Input
                  placeholder="请输入"
                  size="large"
                  className={Style.verify_code}
                  suffix={
                    isRunning ? (
                      `${timeLeft}秒后重新发送`
                    ) : (
                      <Button type="link" onClick={getVerifyCode}>
                        获取验证码
                      </Button>
                    )
                  }
                />
              </Form.Item>
            )}
            {typeVal === "1" && (
              <Form.Item
                name="remember"
                valuePropName="checked"
                style={{
                  textAlign: "left"
                }}
              >
                <Checkbox>自动登录</Checkbox>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                style={{
                  width: "100%"
                }}
              >
                登录
              </Button>
            </Form.Item>
            <Form.Item>
              <a
                style={{ width: "100%" }}
                onClick={() => {
                  router.push("/register");
                }}
              >
                注册账号
              </a>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps<ILogin> = async (ctx) => {
  const { query } = ctx;
  const cId = query?.id;
  let data: any = {}
  if (cId) {
    await fetch(`${getApiHost()}/api/common/login/${cId}`)
      .then(async res => {
        const _data = await res.json();
        data = _data?.data;
      })
      .catch(err => {
        console.log(err);
      });
  }
  return {
    props: {
      isLoginPage: true,
      title: "AI Coach智能培训企业平台",
      data
    }
  };
};
