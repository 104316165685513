import { AxiosRequestConfig } from "axios";
import { parseCookies } from "nookies";
import { getApiHost } from "common/utils";
import { LoginResponse } from "common/interface/interface";
import {
  ISettingInfo,
  IMemberForm,
  IAccompanyForm
} from "common/interface/member";
import Api from "./index";

const { token } = parseCookies();

const uploadAvatarApi = (data: FormData): Promise<LoginResponse<string>> => {
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: token
    },
    method: "post",
    url: `${getApiHost()}/api/common/upload`,
    data
  };
  return Api(config);
};

const searchListApi = (url: string, params: any) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    method: "get",
    url: `${getApiHost()}${url}`,
    params
  };
  return Api(config);
};

const saveMemberApi = (url: string, data: IMemberForm | IAccompanyForm) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    method: "post",
    url: `${getApiHost()}${url}`,
    data
  };
  return Api(config);
};

const deleteMemberApi = (data: { id: number }) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    method: "post",
    url: `${getApiHost()}/api/users/delete`,
    data
  };
  return Api(config);
};

const stopMemberApi = (data: {
  id: number;
  disabled: boolean;
}): Promise<LoginResponse<null>> => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    method: "post",
    url: `${getApiHost()}/api/users/disable`,
    data
  };
  return Api(config);
};

const deleteAccompanyApi = (data: { id: number }) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    method: "post",
    url: `${getApiHost()}/api/tutoring/delete`,
    data
  };
  return Api(config);
};

const stopAccompanyApi = (data: {
  id: number;
  disabled: boolean;
}): Promise<LoginResponse<null>> => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    method: "post",
    url: `${getApiHost()}/api/tutoring/disable`,
    data
  };
  return Api(config);
};

const getSettingInfo = (): Promise<LoginResponse<ISettingInfo>> => {
  const config: AxiosRequestConfig = {
    method: "get",
    headers: {
      Authorization: token
    },
    url: `${getApiHost()}/api/common/settings`
  };
  return Api(config);
};

export {
  uploadAvatarApi,
  saveMemberApi,
  searchListApi,
  deleteMemberApi,
  stopMemberApi,
  deleteAccompanyApi,
  stopAccompanyApi,
  getSettingInfo
};
